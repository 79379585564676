import React from 'react';
import { IonBackButton, IonBadge, IonButton, IonButtons, IonCard, IonContent, IonHeader, IonItem, IonLabel, IonList, IonPage, IonRefresher, IonToolbar, RefresherEventDetail, withIonLifeCycle } from '@ionic/react';
import { Link } from 'react-router-dom';

//	L O C A L I Z A T I O N

import { locale___App } from '../../../App.locale';
import { locale___SbPoiViewComponent } from './sb-poi-view.locale';

//	S T Y L E

import './sb-poi-view.scss';

//	L I B S

import { lib_names } from '../../../libs/lib.names';

//	F U N C S

import { funcs_datetime } from '../../../funcs/funcs.datetime';
import { funcs_numbers } from '../../../funcs/funcs.numbers';

//	S T A T E   -   P R O P S

import { props___SbPoiViewPage, state___SbPoiViewPage, state___SbPoiViewPage___default } from './sb-poi-view.state';

//	T Y P E S

import { type___api___response } from '../../../types/types.api-response';
import { type___poi___favorite_action } from '../../../types/types.types';

//	S E R V I C E S

import { service_LocalizationService } from '../../../services/service-localization';
import { service_RestApiService } from '../../../services/service-api';
import { service_Router } from '../../../services/service-router';

//	C O M P O N E N T S

import Com404Component from '../../../components/com-404/com-404';
import ComButtonShareComponent from '../../../components/com-button-share/com-button-share';
import ComGalleryPlaceholderComponent from '../../../components/com-gallery-placeholder/com-gallery-placeholder';
import ComGallerySliderComponent from '../../../components/com-gallery-slider/com-gallery-slider';
import ComListSeparatorComponent from '../../../components/com-list-separator/com-list-separator';
import ComMapPreviewComponent from '../../../components/com-map-preview/com-map-preview';
import ComMapPreviewAddressComponent from '../../../components/com-map-preview-address/com-map-preview-address';
import ComPoiDisclaimerAcceptanceComponent from '../../../components/com-poi-disclaimer-acceptance/com-poi-disclaimer-acceptance';
import ComPoiDisclaimerViewComponent from '../../../components/com-poi-disclaimer-view/com-poi-disclaimer-view';
import ComPoiEmergencyComponent from '../../../components/com-poi-emergency/com-poi-emergency';
import ComPoiTimetableComponent from '../../../components/com-poi-timetable/com-poi-timetable';
import ComPoiVerifiedComponent from '../../../components/com-poi-verified/com-poi-verified';
import ComSpinnerComponent from '../../../components/com-spinner/com-spinner';
import ComSpinnerInlineComponent from '../../../components/com-spinner-inline/com-spinner-inline';
import ComSpinnerRefresherComponent from '../../../components/com-spinner-refresher/com-spinner-refresher';
import ComSpinnerTagComponent from '../../../components/com-spinner-tag/com-spinner-tag';
import ComTextComponent from '../../../components/com-text/com-text';

//	M O D A L S

import MdMediaViewModal from '../../../modals/md-media-view/md-media-view';

//	C L A S S

class SbPoiViewPage extends React.Component<props___SbPoiViewPage, state___SbPoiViewPage>
{

//#region 																							D E C L A R A T I O N S

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();
	private readonly _API: service_RestApiService = new service_RestApiService();
	private readonly _ROUTER: service_Router = new service_Router();
	
	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();
	private readonly funcs___numbers: funcs_numbers = new funcs_numbers();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___SbPoiViewPage,
	) {
		super(props);
		this.state = state___SbPoiViewPage___default;
	}

//#endregion

//#region 																							R E A D   P R O P S

	private read_props___poi = async () => {
		const ___temp___poi___props: type___api___response = await this._API.poi___read___single(this.state.poi___id);
		this.setState({
			poi___props: ___temp___poi___props.data,
			poi___props___is_loading: false,
		});
	}

	private read_props___poi___events_preview = async () => {
		const ___temp___poi_events_collection: type___api___response = await this._API.events___read___multi('poi_preview', this.state.poi___id, 0);
		this.setState({
			poi_events___collection: ___temp___poi_events_collection.data,
		});
	}

//#endregion

//#region 																							H A N D L E R S

	private handle___page___refresh = (___e: CustomEvent<RefresherEventDetail>) => {
		this.setState({
			poi___props: null,
			poi___props___is_loading: true,
		}, async () => {
			await this.read_props___poi();
			___e.detail.complete();
		});
	}

	private handle___favorite___toggle = (___action: type___poi___favorite_action) => {
		this.setState({
			poi___favorites___is_loading: true,
		}, async () => {
			const ___is_now_favorite: type___api___response = await this._API.poi_favorites___toggle(this.state.poi___id, ___action);
			if (___is_now_favorite) { await this.read_props___poi(); }
			this.setState({
				poi___favorites___is_loading: false,
			});
		});
	}

//#endregion

//#region 																							L I F E C Y C L E

	ionViewDidEnter() : void {
		const ___poi_id: string | null = this._ROUTER.get_id(this.props);
		if (___poi_id) {
			this.setState({
				poi___id: ___poi_id,
			}, async () => {+
				await Promise.all([
					await this.read_props___poi(),
					await this.read_props___poi___events_preview(),
				]);
			});
		}
	}

	ionViewDidLeave() : void {
		this.setState({
			...state___SbPoiViewPage___default
		});
	}

//#endregion

//#region 																							R E T U R N

	render() : React.ReactNode
	{
		return <>
			<IonPage>

				<MdMediaViewModal isOpen={ this.state.ionModal___MdMediaViewModal___visible } media_src={ this.state.ionModal___MdMediaViewModal___argument } event___onDidDismiss={() => { this.setState({ ionModal___MdMediaViewModal___visible: false, ionModal___MdMediaViewModal___argument: '' }); }} />

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="start">
							<IonBackButton defaultHref="/discover" text={ this._LOCALE.translate(locale___App.element___IonBackButton) }></IonBackButton>
						</IonButtons>
						{(this.props.is_logged === true && this.props.is_logged___props !== null && this.state.poi___props___is_loading !== true && this.state.poi___props !== null) ? <>
							<IonButtons slot="end">
								{(this.state.poi___favorites___is_loading === true) ? <>
									<IonButton>
										<ComSpinnerTagComponent />
									</IonButton>
								</> : <>
									{(() => { switch (this.state.poi___props['poi_is_favorite'] as boolean) {
										case true: return <>
											<IonButton onClick={() => { this.handle___favorite___toggle('remove'); }}>
												<i className="fas fa-star"></i>
											</IonButton>
										</>; break;
										case false: return <>
											<IonButton onClick={() => { this.handle___favorite___toggle('add'); }}>
												<i className="far fa-star"></i>
											</IonButton>
										</>; break;
									}})()}
								</>}
							</IonButtons>
						</> : <></>}
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>

					{(this.state.poi___props___is_loading === true) ? <>

						<br />
						<br />
						<br />
						<br />
						<br />
						<br />
						<ComSpinnerComponent size="small"/>

					</> : <>

						<IonRefresher slot="fixed" onIonRefresh={ this.handle___page___refresh }>
							<ComSpinnerRefresherComponent/>
						</IonRefresher>

						{(this.state.poi___props !== null) ? <>
							<div className="sb-page---container sb-poi-view---container">

								{(
									(this.state.poi___props['poi_is_mine'] && this.state.poi___props['poi_is_mine'] === true) ||
									(this.state.poi___props['poi_permissions___events'] && this.state.poi___props['poi_permissions___events'] === true)
								) ? <>
									<IonCard>
										<IonList>
											{(this.state.poi___props['poi_is_mine'] && this.state.poi___props['poi_is_mine'] === true) ? <>
												<IonItem className="is-ion-item-button" button={ true } routerLink={ '/view/poi/' + this.state.poi___props['poi_id'] + '/feed' } routerDirection="forward">
													<i className="far fa-comments"></i>
													<IonLabel>{ this._LOCALE.translate(locale___SbPoiViewComponent.poi_commands___feed) }</IonLabel>
												</IonItem>
											</> : <></>} 
											{(
												(this.state.poi___props['poi_is_mine'] && this.state.poi___props['poi_is_mine'] === true) ||
												(this.state.poi___props['poi_permissions___events'] && this.state.poi___props['poi_permissions___events'] === true)
											) ? <>
												<IonItem className="is-ion-item-button" button={ true } routerLink={ '/view/poi/' + this.state.poi___props['poi_id'] + '/settings' } routerDirection="forward">
													<i className="fas fa-cog"></i>
													<IonLabel>{ this._LOCALE.translate(locale___SbPoiViewComponent.poi_commands___settings) }</IonLabel>
												</IonItem>
											</> : <></>} 
										</IonList>
									</IonCard>
									<ComListSeparatorComponent />
								</> : <></>}

								{(!(this.state.poi___props['poi_accepted'] && this.state.poi___props['poi_accepted'] === true)) ? <>
									<ComPoiDisclaimerAcceptanceComponent />
									<ComListSeparatorComponent />
								</> : <></>}
							
								{(this.state.poi___props['poi_category']['poi_category_emergency'] === true) ? <>
									<ComPoiEmergencyComponent/>
									<br />
								</> : <></>}

								<div className="sb-poi-view---image">
									{(this.state.poi___props['poi_gallery'] && this.state.poi___props['poi_gallery'].length > 0) ? <>
										<ComGallerySliderComponent gallery___images={ this.state.poi___props['poi_gallery'] } image___aspect_ratio={ lib_names.aspectRatios.poi.ar }/>
									</> : <>
										<ComGalleryPlaceholderComponent placeholder_path={ '/assets/markers/poi/' + this.state.poi___props['poi_category']['poi_category_icon'] + '.svg' } />
									</>}
								</div>

								<h6>
									<Link to={ '/view/poi/category/' + this.state.poi___props['poi_category']['poi_category_id'] }>{ this.state.poi___props['poi_category']['poi_category_name'] }</Link>
									<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.distance_from_you, [this.funcs___numbers.number___format_as_string___distance(this.state.poi___props['poi_coords_distance'])]) }</span>
								</h6>

								<h3>
									<span>{ this.state.poi___props['poi_name'] }</span>
									{(this.state.poi___props['poi_verified'] && this.state.poi___props['poi_verified'] === true) ? <>
										<ComPoiVerifiedComponent popup={ true }/>
									</> : <></>}
								</h3>

								{(this.state.poi___props['poi_events_count'] && this.state.poi___props['poi_events_count'] > 0) ? <>
									{(this.state.poi_events___collection.length > 0) ? <>
										<section className="sb-poi-view---container---events">
											<IonList>
												{this.state.poi_events___collection.map((___p_e: any, ___p_e___idx: number) => {
													return <>
														<IonItem key={ ___p_e___idx } className="is-ion-item-button" routerLink={ '/view/events/' + ___p_e['event_id'] } routerDirection="forward">
															<img src={ ___p_e['event_poster'] } />
															<IonLabel>{ ___p_e['event_name'] }</IonLabel>
															{(___p_e['event_is_now'] && ___p_e['event_is_now'] === true) ? <>
																<IonBadge slot="end" color="primary">{ this._LOCALE.translate(locale___SbPoiViewComponent.poi_events___event_is_now) }</IonBadge>
															</> : <>
																{(___p_e['event_ended'] && ___p_e['event_ended'] === true) ? <>
																	<IonBadge slot="end" color="light">{ this._LOCALE.translate(locale___SbPoiViewComponent.poi_events___event_ended) }</IonBadge>
																</> : <>
																	<IonBadge slot="end" color="primary">{ this.funcs___datetime.datetime___get___date(___p_e['event_datetime_begin']) }</IonBadge>
																</>}
															</>}
														</IonItem>
													</>;
												})}
											</IonList>
										</section>
									</> : <></>}
								</> : <></>}

								{(
									(this.state.poi___props['poi_posts_count'] && this.state.poi___props['poi_posts_count'] > 0) ||
									(this.state.poi___props['poi_tales_count'] && this.state.poi___props['poi_tales_count'] > 0) ||
									(this.state.poi___props['poi_service_digitalmenu'] && this.state.poi___props['poi_service_digitalmenu'] !== null) ||
									(this.state.poi___props['poi_externalService___carshubConnector'] && this.state.poi___props['poi_externalService___carshubConnector'] !== null)
								) ? <>
									<section className="sb-poi-view---container---view-content">
										
										{(this.state.poi___props['poi_posts_count'] && this.state.poi___props['poi_posts_count'] > 0) ? <>
											<Link to={ '/view/poi/' + this.state.poi___props['poi_id'] + '/feed' }>
												<i className="far fa-comments"></i>
												<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.view_contents___feed) }</span>
											</Link>
										</> : <></>}

										{(this.state.poi___props['poi_tales_count'] && this.state.poi___props['poi_tales_count'] > 0) ? <>
											{(this.props.is_logged && this.props.is_logged === true) ? <>
												<Link to={ '/view/poi/' + this.state.poi___props['poi_id'] + '/tales' }>
													<i className="far fa-images"></i>
													<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.view_contents___tales) }</span>
												</Link>
											</> : <></>}
										</> : <></>}

										{(this.state.poi___props['poi_service_digitalmenu'] && this.state.poi___props['poi_service_digitalmenu'] !== null) ? <>
											<Link to={ '/view/poi/' + this.state.poi___props['poi_id'] + '/digitalmenu' }>
												<i className="fas fa-utensils"></i>
												<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.view_contents___digitalmenu) }</span>
											</Link>
										</> : <></>}

										{(this.state.poi___props['poi_externalService___carshubConnector'] && this.state.poi___props['poi_externalService___carshubConnector'] !== null) ? <>
											<Link to={ '/catalog/carshub/' + this.state.poi___props['poi_id'] }>
												<i className="fas fa-car"></i>
												<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.view_contents___cars) }</span>
											</Link>
										</> : <></>}

									</section>
								</> : <></>}

								{(this.state.poi___props['poi_description'] && this.state.poi___props['poi_description'].length > 0) ? <>
									<ComTextComponent text={ this.state.poi___props['poi_description'] }/>
									<br />
								</> : <></>}

								{/**
									{(this.state.poi_posts___collection___is_loading === true) ? <>
										<ComSpinnerInlineComponent/>
									</> : <>
										{(this.state.poi_posts___collection.length > 0) ? <>
											<section className="sb-poi-view---container---updates">
												<Link to={ '/view/poi/' + this.state.poi___props['poi_id'] + '/feed' }>
													{this.state.poi_posts___collection.map((___pp: any, ___pp___idx: number) => { 
														return <>
															<ComPostPoiComponent key={ ___pp___idx } post___props={ ___pp }/>
														</>;
													})}
													<h6>{ this._LOCALE.translate(locale___SbPoiViewComponent.view_contents___feed) }</h6>
												</Link>
											</section>
										</> : <></>}
									</>}
								*/}

								{((
									(this.state.poi___props['poi_contact_phone'] && this.state.poi___props['poi_contact_phone'].length > 0) ||
									(this.state.poi___props['poi_contact_email'] && this.state.poi___props['poi_contact_email'].length > 0) ||
									(this.state.poi___props['poi_contact_website'] && this.state.poi___props['poi_contact_website'].length > 0)
								) || ( this.state.poi___props['poi_contact_socials'] && (
									(this.state.poi___props['poi_contact_socials']['facebook'] && this.state.poi___props['poi_contact_socials']['facebook'].length > 0) ||
									(this.state.poi___props['poi_contact_socials']['instagram'] && this.state.poi___props['poi_contact_socials']['instagram'].length > 0) ||
									(this.state.poi___props['poi_contact_socials']['youtube'] && this.state.poi___props['poi_contact_socials']['youtube'].length > 0) ||
									(this.state.poi___props['poi_contact_socials']['tiktok'] && this.state.poi___props['poi_contact_socials']['tiktok'].length > 0) ||
									(this.state.poi___props['poi_contact_socials']['twitter'] && this.state.poi___props['poi_contact_socials']['twitter'].length > 0)
								))) ? <>
									<section className="sb-poi-view---container---contacts">
										{(
											(this.state.poi___props['poi_contact_phone'] && this.state.poi___props['poi_contact_phone'].length > 0) ||
											(this.state.poi___props['poi_contact_email'] && this.state.poi___props['poi_contact_email'].length > 0) ||
											(this.state.poi___props['poi_contact_website'] && this.state.poi___props['poi_contact_website'].length > 0)
										) ? <>
											<section>
												{(this.state.poi___props['poi_contact_phone'] && this.state.poi___props['poi_contact_phone'].length > 0) ? <>
													<a href={ 'tel:' + this.state.poi___props['poi_contact_phone'] }>
														<i className="fas fa-phone"></i>
														<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.contacts___phone) }</span>
													</a>
												</> : <></>}
												{(this.state.poi___props['poi_contact_email'] && this.state.poi___props['poi_contact_email'].length > 0) ? <>
													<a href={ 'mailto:' + this.state.poi___props['poi_contact_email'] }>
														<i className="fas fa-envelope"></i>
														<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.contacts___email) }</span>
													</a>
												</> : <></>}
												{(this.state.poi___props['poi_contact_website'] && this.state.poi___props['poi_contact_website'].length > 0) ? <>
													<a href={ this.state.poi___props['poi_contact_website'] } target="_blank">
														<i className="fas fa-globe"></i>
														<span>{ this._LOCALE.translate(locale___SbPoiViewComponent.contacts___website) }</span>
													</a>
												</> : <></>}
											</section>
										</> : <></>}
										{(
											this.state.poi___props['poi_contact_socials'] && (
											(this.state.poi___props['poi_contact_socials']['facebook'] && this.state.poi___props['poi_contact_socials']['facebook'].length > 0) ||
											(this.state.poi___props['poi_contact_socials']['instagram'] && this.state.poi___props['poi_contact_socials']['instagram'].length > 0) ||
											(this.state.poi___props['poi_contact_socials']['youtube'] && this.state.poi___props['poi_contact_socials']['youtube'].length > 0) ||
											(this.state.poi___props['poi_contact_socials']['tiktok'] && this.state.poi___props['poi_contact_socials']['tiktok'].length > 0) ||
											(this.state.poi___props['poi_contact_socials']['twitter'] && this.state.poi___props['poi_contact_socials']['twitter'].length > 0)
										)) ? <>
											<section>
												{((this.state.poi___props['poi_contact_socials']['facebook'] && this.state.poi___props['poi_contact_socials']['facebook'].length > 0)) ? <>
													<a href={ this.state.poi___props['poi_contact_socials']['facebook'] } target="_blank">
														<i className="fab fa-facebook"></i>
														<span>Facebook</span>
													</a>
												</> : <></>}
												{((this.state.poi___props['poi_contact_socials']['instagram'] && this.state.poi___props['poi_contact_socials']['instagram'].length > 0)) ? <>
													<a href={ this.state.poi___props['poi_contact_socials']['instagram'] } target="_blank">
														<i className="fab fa-instagram"></i>
														<span>Instagram</span>
													</a>
												</> : <></>}
												{((this.state.poi___props['poi_contact_socials']['youtube'] && this.state.poi___props['poi_contact_socials']['youtube'].length > 0)) ? <>
													<a href={ this.state.poi___props['poi_contact_socials']['youtube'] } target="_blank">
														<i className="fab fa-youtube"></i>
														<span>YouTube</span>
													</a>
												</> : <></>}
												{((this.state.poi___props['poi_contact_socials']['tiktok'] && this.state.poi___props['poi_contact_socials']['tiktok'].length > 0)) ? <>
													<a href={ this.state.poi___props['poi_contact_socials']['tiktok'] } target="_blank">
														<i className="fab fa-tiktok"></i>
														<span>TikTok</span>
													</a>
												</> : <></>}
												{((this.state.poi___props['poi_contact_socials']['twitter'] && this.state.poi___props['poi_contact_socials']['twitter'].length > 0)) ? <>
													<a href={ this.state.poi___props['poi_contact_socials']['twitter'] } target="_blank">
														<i className="fab fa-twitter"></i>
														<span>Twitter</span>
													</a>
												</> : <></>}
											</section>
										</> : <></>}
									</section>
								</> : <></>}

								<ComButtonShareComponent element_type="poi" element={ this.state.poi___props } is_logged={ this.props.is_logged } is_logged___props={ this.props.is_logged___props }/>

								{/*
									{(this.state.poi___props['poi_attributes'] && this.state.poi___props['poi_attributes'].length > 0) ? <>
										<section className="sb-poi-view---container---attributes">
											{this.state.poi___props['poi_attributes'].map((___p_a: any, ___p_a___idx: number) => {
												return <>
													<div key={ ___p_a___idx }>
														<i className={ 'fas fa-' + ___p_a['poi_attribute_icon'] }></i>
														<span>{ ___p_a['poi_attribute_name'] }</span>
													</div>
												</>;	
											})}
										</section>
										<br />
									</> : <></>}
								*/}

								{(this.state.poi___props['poi_timetable'] !== null && this.state.poi___props['poi_timetable'] !== 'none') ? <>
									<ComPoiTimetableComponent timetable_props={ this.state.poi___props['poi_timetable'] } />
									<br />
								</> : <></>}

								<ComMapPreviewAddressComponent coords={{ lng: this.state.poi___props['poi_coords_lng'], lat: this.state.poi___props['poi_coords_lat'] }} canOpenRoute={ true }/>
								<ComMapPreviewComponent map_coords={{ lng: this.state.poi___props['poi_coords_lng'], lat: this.state.poi___props['poi_coords_lat'] }} canOpenRoute={ true }/>

								<br />
								<br />
								<br />

								<ComPoiDisclaimerViewComponent/>

							</div>
						</> : <>
							<Com404Component/>
						</>}
					</>}

				</IonContent>

			</IonPage>
		</>;
	}

//#endregion

}

//	E X P O R T

export default withIonLifeCycle(SbPoiViewPage);