//	L I B S

import { lib_api_shared } from '../libs/lib.api.shared';
import { lib_names } from '../libs/lib.names';
import { lib_names_shared } from '../libs/lib.names.shared';

//	T Y P E S

import { type___action___element___like } from '../types/types.actions';
import { type___api___response } from '../types/types.api-response';
import { type___geo___lngLat, type___poi___favorite_action, type___poi___socials, type___post___attachment_type, type___post___target, type___post___visibility, type___search___target, type___user___follow___action_response, type___user___follow___action_type, type___user___friends___action, type___user___interests, type___user_setting___property } from '../types/types.types';
import { type___locale___language } from '../types/types.locale';
import { type___digitalmenu___currency, type___digitalmenu___dish_measurement_unit } from '../types/types.digitalmenu';
import { type___message___attachment, type___message___like_action } from '../types/types.messages';
import { type___cookies___accepted } from '../types/types.cookies';

//	S E R V I C E S

import { service_GeolocationService } from './service-geolocation';

//	E X P O R T

export class service_RestApiService
{

//#region 																							D E C L A R A T I O N S

	private _GEOLOCATION: service_GeolocationService = new service_GeolocationService();
	private _HEADERS: any = { 'Content-Type': lib_api_shared.content_type };

//#endregion

//#region 																							L O A D   P R O P S

	private load_props___language() : void
	{
		const ___language: type___locale___language | null = localStorage.getItem(lib_names.localStorage.user___language) as type___locale___language | null ?? null;
		if (___language) { this._HEADERS[lib_api_shared.headers.extra___x_locale] = ___language as type___locale___language; }
	}

	private load_props___tracking() : void
	{
		const ___cookies___accepted: type___cookies___accepted | null = localStorage.getItem(lib_names.localStorage.user___cookies_accepted) as type___cookies___accepted | null ?? null;
		if (___cookies___accepted) { this._HEADERS[lib_api_shared.headers.extra___x_tracking] = ___cookies___accepted as type___cookies___accepted; }
	}

	private load_props___geolocation() : void
	{
		const ___geolocation___stringified: string | null = localStorage.getItem(lib_names.localStorage.user___location);
		this._HEADERS[lib_api_shared.headers.extra___x_coords] = ___geolocation___stringified as string;
	}

	private load_props___whoami() : void
	{
		const ___whoami_token: string | null = localStorage.getItem(lib_names.localStorage.user___logintoken) as string | null ?? null;
		if (___whoami_token) { this._HEADERS[lib_api_shared.headers.default___authorization] = lib_api_shared.headers.default___authorization___bearer + ' ' + ___whoami_token as string; }
	}

	private load_props___whoami___interests() : void
	{
		const ___interests___event___string: string | null = localStorage.getItem(lib_names.localStorage.user___interests___events) ?? null;
		const ___interests___event___array: string[] = (___interests___event___string && ___interests___event___string.length > 0) ? JSON.parse(___interests___event___string) : [];
		const ___interests___poi___string: string | null = localStorage.getItem(lib_names.localStorage.user___interests___poi) ?? null;
		const ___interests___poi___array: string[] = (___interests___poi___string && ___interests___poi___string.length > 0) ? JSON.parse(___interests___poi___string) : [];
		const ___interest: string[] = [...___interests___event___array, ...___interests___poi___array];
		if (___interest) { this._HEADERS[lib_api_shared.headers.extra___x_interests_poi_categories] = ___interest.join(','); }
	}

//#endregion

//#region 																							R E T U R N   S E R V E R   R E S P O N S E

	private async return_server_response(___action: string, ___target: string, ___to_post: any | {}, ___signal: AbortSignal | null = null) : Promise<type___api___response>
	{

		this.load_props___language();
		this.load_props___tracking();
		this.load_props___whoami();
		this.load_props___geolocation(),
		this.load_props___whoami___interests()

	//	console.log('this._HEADERS',this._HEADERS)

		const ___s_route: string = lib_names_shared.endpoints.server_nearby + ___action + '/' + ___target;
		try {
			const ___s_response: Response = await fetch(___s_route, {
				method: 'POST',
				headers: this._HEADERS,
				body: JSON.stringify(___to_post),
				signal: ___signal
			});
			if (___s_response.ok) {
				try {
					const ___server_response: any = await ___s_response.json();
					return (___server_response !== null && ___server_response !== undefined) ? ___server_response : null;
				} catch (___e: any) {
					return { response: 'error', message: 'api-error', data: {} };
				}
			} else {
				return { response: 'error', message: 'api-error', data: {} };
			}
		} catch (___e_main: any) {
			console.error('Error fetching server response:', ___e_main);
			return { response: 'error', message: 'api-error', data: {} };
		}

	}

//#endregion

//#region 																							A U T H   P A S S W O R D   C H A N G E

	async auth___perform___password_change(___old_password: string | null, ___new_password: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'old_password': ___old_password, 'new_password': ___new_password };
		return await this.return_server_response('auth', 'password_change', ___to_post);
	}

//#endregion

//#region 																							A U T H   D E L E T E   A C C O U N T

	async auth_delete_account___delete_account(___password: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'password': ___password };
		return await this.return_server_response('auth_delete_account', 'delete', ___to_post);
	}

//#endregion

//#region 																							C A T A L O G   C A R S H U B

	async catalog_carshub___vehicles___read___multi(___poi_id: string, ___last_index: number) : Promise<type___api___response>
	{
		const ___to_post: any = { 'poi_id': ___poi_id, 'last_index': ___last_index };
		return await this.return_server_response('poi_catalog_carshub', 'vehicles/read_multi', ___to_post);
	}

	async catalog_carshub___vehicles___read___single(___vehicle_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'vehicle_id': ___vehicle_id };
		return await this.return_server_response('poi_catalog_carshub', 'vehicles/read_single', ___to_post);
	}

//#endregion

//#region 																							C H A T S

	async chats___read___multi(___filter: 'all' | 'unread', ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'filter': ___filter, 'last_index': ___last_index };
		return await this.return_server_response('chats', 'read_multi', ___to_post);
	}

//#endregion

//#region 																							C H A T   M E S S A G E S

	async chats_messages___mark_as___read(___chat_with: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'chat_with': ___chat_with };
		return await this.return_server_response('chats_messages', 'mark_as_read', ___to_post);
	}

	async chats_messages___read___multi(___chat_with: string, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'chat_with': ___chat_with, 'last_index': ___last_index };
		return await this.return_server_response('chats_messages', 'read_multi', ___to_post);
	}
	
	async chats_messages___send(___send_to: string, ___message_text: string, ___attachment: type___message___attachment | null = null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'send_to': ___send_to, 'message_text': ___message_text, 'attachment': ___attachment };
		return await this.return_server_response('chats_messages', 'send', ___to_post);
	}

	async chats_messages___like___toggle(___message_id: string, ___action: type___message___like_action) : Promise<type___api___response>
	{
		const ___to_post: any = { 'message_id': ___message_id, 'action': ___action };
		return await this.return_server_response('chats_messages', 'like_toggle', ___to_post);
	}

//#endregion

//#region 																							C R E D I T S

	async credits___read___balance() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('credits', 'read_balance', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S

	async digitalmenus___create(___poi_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'poi_id': ___poi_id };
		return await this.return_server_response('digitalmenus', 'create', ___to_post);
	}

	async digitalmenus___read___single(___target: 'poi' | 'digitalmenu', ___target_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'target': ___target, 'target_id': ___target_id };
		return await this.return_server_response('digitalmenus', 'read_single', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S   D I S H E S

	async digitalmenus_dishes___read___single(___dish_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'dish_id': ___dish_id };
		return await this.return_server_response('digitalmenus_dishes', 'read_single', ___to_post);
	}

	async digitalmenus_dishes___submit(___d_digitalmenu: string, ___d_id: string | null, ___d_category: string, ___d_name: string, ___d_description: string, ___d_price: number, ___d_price_currency: type___digitalmenu___currency, ___d_measurement_unit: type___digitalmenu___dish_measurement_unit, ___d_allergens: string[]) : Promise<type___api___response>
	{
		const ___to_post: any = { 'd_digitalmenu': ___d_digitalmenu, 'd_id': ___d_id, 'd_category': ___d_category, 'd_name': ___d_name, 'd_description': ___d_description, 'd_price': ___d_price, 'd_price_currency': ___d_price_currency, 'd_measurement_unit': ___d_measurement_unit, 'd_allergens': ___d_allergens };
		return await this.return_server_response('digitalmenus_dishes', 'submit', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S   D I S H E S   A L L E R G E N S

	async digitalmenus_dishes_allergens___read___multi(___order: 'standard' | 'alphabetical' = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'order': ___order };
		return await this.return_server_response('digitalmenus_dishes_allergens', 'read_multi', ___to_post);
	}

//#endregion

//#region 																							D I G I T A L M E N U S   D I S H E S   C A T E G O R I E S

	async digitalmenus_dishes_categories___read___multi(___order: 'standard' | 'alphabetical' = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'order': ___order };
		return await this.return_server_response('digitalmenus_dishes_categories', 'read_multi', ___to_post);
	}

	async digitalmenus_dishes_categories___read___single(___dish_category_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'dish_category_id': ___dish_category_id };
		return await this.return_server_response('digitalmenus_dishes_categories', 'read_single', ___to_post);
	}

//#endregion

//#region 																							E V E N T S

	async events___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('events', 'read_multi', ___to_post);
	}

	async events___read___single(___event_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id };
		return await this.return_server_response('events', 'read_single', ___to_post);
	}

	async events___submit(___e_id: string | null, ___e_category: string, ___e_poster: string, ___e_name: string, ___e_description: string, ___e_coords: type___geo___lngLat, ___e_poi: string | null, ___e_datetime_begin: string, ___e_datetime_end: string, ___e_datetime_available: string, ___e_contact_phone: string, ___e_contact_email: string, ___e_user_public: boolean, ___e_custom_icon: boolean) : Promise<type___api___response>
	{
		const ___to_post: any = { 'e_id': ___e_id, 'e_category': ___e_category, 'e_poster': ___e_poster, 'e_name': ___e_name, 'e_description': ___e_description, 'e_coords': ___e_coords, 'e_poi': ___e_poi, 'e_datetime_begin': ___e_datetime_begin, 'e_datetime_end': ___e_datetime_end, 'e_datetime_available': ___e_datetime_available,'e_contact_phone': ___e_contact_phone, 'e_contact_email': ___e_contact_email, 'e_user_public': ___e_user_public, 'e_custom_icon': ___e_custom_icon };
		return await this.return_server_response('events', 'submit', ___to_post);
	}

//#endregion

//#region 																							E V E N T S   A T T R I B U T E S

	async events_attributes___dresscodes___read___multi(___genres_ids: string[] = []) : Promise<type___api___response>
	{
		const ___to_post: any = { 'dresscodes_ids': ___genres_ids };
		return await this.return_server_response('events_attributes', 'dresscodes/read_multi', ___to_post);
	}

	async events_attributes___musicgenres___read___multi(___genres_ids: string[] = []) : Promise<type___api___response>
	{
		const ___to_post: any = { 'musicgenres_ids': ___genres_ids };
		return await this.return_server_response('events_attributes', 'musicgenres/read_multi', ___to_post);
	}

//#endregion

//#region 																							E V E N T S   C A T E G O R I E S

	async events_categories___read___multi() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('events_categories', 'read_multi', ___to_post);
	}

	async events_categories___read___single(___e_category_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_category_id': ___e_category_id };
		return await this.return_server_response('events_categories', 'read_single', ___to_post);
	}

//#endregion

//#region 																							E V E N T S   I N V I T E S

	async events_invites___read___single(___event_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'invite_id': ___event_id };
		return await this.return_server_response('events_invites', 'read_single', ___to_post);
	}






	









	async events_invites___read___targets(___event_id: string, ___invite_radius: number, ___invite_multiplier: number) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id, 'invite_radius': ___invite_radius, 'invite_multiplier': ___invite_multiplier };
		return await this.return_server_response('events_invites', 'read_targets', ___to_post);
	}

	async events_invites___send(___event_id: string, ___invite_radius: number, ___invite_multiplier: number, ___invite_expiration: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'event_id': ___event_id, 'invite_radius': ___invite_radius, 'invite_multiplier': ___invite_multiplier , 'invite_expiration': ___invite_expiration };
		return await this.return_server_response('events_invites', 'send', ___to_post);
	}

//#endregion

//#region 																							M A P

	async map___read___events(___map_center: [ number, number ], ___skip_ids: string[], ___signal: AbortSignal) : Promise<type___api___response>
	{
		const ___to_post: any = { 'map_center': ___map_center, 'skip_ids': ___skip_ids };
		return await this.return_server_response('map', 'read_events', ___to_post, ___signal);
	}

	async map___read___poi(___map_center: [ number, number ], ___skip_ids: string[], ___signal: AbortSignal) : Promise<type___api___response>
	{
		const ___to_post: any = { 'map_center': ___map_center, 'skip_ids': ___skip_ids };
		return await this.return_server_response('map', 'read_poi', ___to_post, ___signal);
	}

	async map___read___tales(___map_center: [ number, number ], ___skip_ids: string[], ___signal: AbortSignal) : Promise<type___api___response>
	{
		const ___to_post: any = { 'map_center': ___map_center, 'skip_ids': ___skip_ids };
		return await this.return_server_response('map', 'read_tales', ___to_post, ___signal);
	}

//#endregion

//#region 																							N O T I F I C A T I O N S

	async notifications___client___register(___platform: 'ios' | 'android', ___user_token: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'platform': ___platform, 'user_token': ___user_token };
		return await this.return_server_response('notifications', 'client_register', ___to_post);
	}

	async notifications___client___unregister(___user_token: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'user_token': ___user_token };
		return await this.return_server_response('notifications', 'client_unregister', ___to_post);
	}

	async notifications___count() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('notifications', 'count', ___to_post);
	}

	async notifications___read___multi(___search_target: 'all' | 'preview', ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'last_index': ___last_index };
		return await this.return_server_response('notifications', 'read_multi', ___to_post);
	}

	async notifications___read___single(___notification_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'notification_id': ___notification_id };
		return await this.return_server_response('notifications', 'read_single', ___to_post);
	}

	async notifications___mark_as_read(___target: 'all' | 'single', ___notification_id: string | null = null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'target': ___target, 'notification_id': ___notification_id };
		return await this.return_server_response('notifications', 'mark_as_read', ___to_post);
	}

//#endregion

//#region 																							P O I

	async poi___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('poi', 'read_multi', ___to_post);
	}

	async poi___read___single(___p_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'p_id': ___p_id };
		return await this.return_server_response('poi', 'read_single', ___to_post);
	}
	
	async poi___submit(___p_id: string | null, ___p_category: string, ___p_name: string, ___p_description: string, ___poi_gallery: string[], ___p_coords: type___geo___lngLat, ___p_contact_phone: string, ___p_contact_email: string, ___p_contact_website: string, ___p_contact_socials: type___poi___socials, ___p_attributes: string[], ___p_timetable: any) : Promise<type___api___response>
	{
		const ___to_post: any = { 'p_id': ___p_id, 'p_category': ___p_category, 'p_name': ___p_name, 'p_description': ___p_description, 'p_gallery': ___poi_gallery, 'p_coords': ___p_coords, 'p_contact_phone': ___p_contact_phone, 'p_contact_email': ___p_contact_email, 'p_contact_website': ___p_contact_website, 'p_attributes': ___p_attributes, 'p_timetable': ___p_timetable };
		return await this.return_server_response('poi', 'submit', ___to_post);
	}

	async poi___role_edit(___action: 'add' | 'remove', ___poi_id: string, ___user_id: string, ___user_role: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'action': ___action, 'poi_id': ___poi_id, 'user_id': ___user_id, 'user_role': ___user_role };
		return await this.return_server_response('poi', 'role_edit', ___to_post);
	}

//#region 																							P O I   A T T R I B U T E S

	async poi_attributes___read___multi() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('poi_attributes', 'read_multi', ___to_post);
	}

	async poi_attributes___read___single(___a_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'a_id': ___a_id };
		return await this.return_server_response('poi_attributes', 'read_single', ___to_post);
	}

//#endregion

//#region 																							P O I   C A T E G O R I E S

	async poi_categories___read___multi(___c_parent: string | null, ___c_read_children: boolean = false) : Promise<type___api___response>
	{
		const ___to_post: any = { 'c_parent': ___c_parent, 'c_read_children': ___c_read_children };
		return await this.return_server_response('poi_categories', 'read_multi', ___to_post);
	}

	async poi_categories___read___single(___c_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'c_id': ___c_id };
		return await this.return_server_response('poi_categories', 'read_single', ___to_post);
	}

//#endregion

//#region 																							P O I   F A V O R I T E S

	async poi_favorites___toggle(___poi_id: string, ___action: type___poi___favorite_action) : Promise<type___api___response>
	{
		const ___to_post: any = { 'poi_id': ___poi_id, 'action': ___action };
		return await this.return_server_response('poi_favorites', 'toggle', ___to_post);
	}

//#endregion

//#region 																							P O S T S

	async posts___read___multi(___search_target: string , ___search_param: string | null, ___last_index: number, ___order: string = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index, 'order': ___order };
		return await this.return_server_response('posts', 'read_multi', ___to_post);
	}

	async posts___read___single(___p_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'post_id': ___p_id };
		return await this.return_server_response('posts', 'read_single', ___to_post);
	}

	async posts___submit(___p_t_type: type___post___target, ___p_t_id: string | null, ___p_text: string, ___p_visibility: type___post___visibility, ___p_share_element_type: type___post___attachment_type | null, ___p_share_element_id: string | null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'p_target_type': ___p_t_type, 'p_target_id': ___p_t_id, 'p_text': ___p_text, 'p_visibility': ___p_visibility, 'p_share_element_type': ___p_share_element_type ?? null, 'p_share_element_id': ___p_share_element_id ?? null };
		return await this.return_server_response('posts', 'submit', ___to_post);
	}

	async posts___delete(___post_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'post_id': ___post_id };
		return await this.return_server_response('posts', 'delete', ___to_post);
	}

//#endregion

//#region 																							P O S T S   C O M M E N T S

	async posts_comments___read___multi(___post_id: string , ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'post_id': ___post_id, 'last_index': ___last_index };
		return await this.return_server_response('posts_comments', 'read_multi', ___to_post);
	}

	async posts_comments___submit(___post_id: string, ___comment_text: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'post_id': ___post_id, 'comment_text': ___comment_text };
		return await this.return_server_response('posts_comments', 'submit', ___to_post);
	}
	
	async posts_comments___delete(___post_comment_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'post_comment_id': ___post_comment_id };
		return await this.return_server_response('posts_comments', 'delete', ___to_post);
	}

//#endregion

//#region 																							P O S T S   L I K E S

	async posts_likes___toggle(___post_id: string, ___action: type___action___element___like) : Promise<type___api___response>
	{
		const ___to_post: any = { 'post_id': ___post_id, 'action': ___action };
		return await this.return_server_response('posts_likes', 'toggle', ___to_post);
	}

//#endregion

//#region 																							S E A R C H

	async search___locations(___search_target: 'cities' | 'addresses', ___search_param: string, ___limit: number = lib_names.mongo.query_limit_few) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'limit': ___limit };
		return await this.return_server_response('search', 'locations', ___to_post);
	}

	async search___discover(___search_param: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_param': ___search_param };
		return await this.return_server_response('search', 'discover', ___to_post);
	}

	async search___full(___search_target: type___search___target, ___search_param: string, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('search', 'full', ___to_post);
	}

//#endregion

//#region 																							S T A T S

	async stats___platform() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('stats', 'platform', ___to_post);
	}

//#endregion

//#region 																							T A L E S

	async tales___read___multi(___search_target: string, ___search_param: string | null, ___last_index: number = 0, ___order: 'standard' | 'popular' = 'standard') : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index, 'order': ___order };
		return await this.return_server_response('tales', 'read_multi', ___to_post);
	}

	async tales___read___single(___t_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 't_id': ___t_id };
		return await this.return_server_response('tales', 'read_single', ___to_post);
	}

	async tales___submit(___t_media: string, ___t_text: string, ___t_coords: type___geo___lngLat, ___t_poi: string | null) : Promise<type___api___response>
	{
		const ___to_post: any = { 't_media': ___t_media, 't_text': ___t_text, 't_coords': ___t_coords, 't_poi': ___t_poi };
		return await this.return_server_response('tales', 'submit', ___to_post);
	}

	async tales___delete(___tale_id: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'tale_id': ___tale_id };
		return await this.return_server_response('tales', 'delete', ___to_post);
	}

//#endregion

//#region 																							T A L E S   L I K E S

	async tales_likes___toggle(___tale_id: string, ___action: type___action___element___like) : Promise<type___api___response>
	{
		const ___to_post: any = { 'tale_id': ___tale_id, 'action': ___action };
		return await this.return_server_response('tales_likes', 'toggle', ___to_post);
	}

//#endregion

//#region 																							U S E R S

	async users___read___multi(___search_target: string, ___search_param: string | number | null, ___last_index: number = 0) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param, 'last_index': ___last_index };
		return await this.return_server_response('users', 'read_multi', ___to_post);
	}

	async users___read___single(___search_target: 'username' | 'id' | 'me', ___search_param: string | null = null) : Promise<type___api___response>
	{
		const ___to_post: any = { 'search_target': ___search_target, 'search_param': ___search_param };
		return await this.return_server_response('users', 'read_single', ___to_post);
	}

	async users___submit_changes(___u_img_cover: string | null, ___u_img_profile: string | null, ___u_name: string, ___u_bio: string, ___u_contact_phone: string, ___u_contact_email: string) : Promise<type___api___response>
	{
		const ___to_post: any = { 'u_img_cover': ___u_img_cover, 'u_img_profile': ___u_img_profile, 'u_name': ___u_name, 'u_bio': ___u_bio, 'u_contact_phone': ___u_contact_phone, 'u_contact_email': ___u_contact_email };
		return await this.return_server_response('users', 'submit_changes', ___to_post);
	}

//#endregion

//#region 																							U S E R S   F O L L O W S

	async users_follows___toggle(___u_id: string, ___action: type___user___follow___action_type) : Promise<type___api___response>
	{
		const ___to_post: any = { 'u_id': ___u_id, 'action': ___action };
		return await this.return_server_response('users_follows', 'toggle', ___to_post);
	}

	async users_follows___follow_response(___u_id: string, ___action: type___user___follow___action_response) : Promise<type___api___response>
	{
		const ___to_post: any = { 'u_id': ___u_id, 'action': ___action };
		return await this.return_server_response('users_follows', 'follow_response', ___to_post);
	}

//#endregion

//#region 																							U S E R S   F R I E N D S

	async users_friends___read_list() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('users_friends', 'read_list', ___to_post);
	}

	async users_friends___toggle(___user_id: string, ___action: type___user___friends___action) : Promise<type___api___response>
	{
		const ___to_post: any = { 'user_id': ___user_id, 'action': ___action };
		return await this.return_server_response('users_friends', 'toggle', ___to_post);
	}

//#endregion

//#region 																							U S E R S   I N T E R E S T S

	async users_interests___read_all() : Promise<type___api___response>
	{
		const ___to_post: any = { };
		return await this.return_server_response('users_interest', 'read_all', ___to_post);
	}

	async users_interests___set___events(___array_ec: string[]) : Promise<type___api___response>
	{
		const ___to_post: any = { 'array_ec': ___array_ec };
		return await this.return_server_response('users_interest', 'set_events', ___to_post);
	}

	async users_interests___set___poi(___array_pc: string[]) : Promise<type___api___response>
	{
		const ___to_post: any = { 'array_pc': ___array_pc };
		return await this.return_server_response('users_interest', 'set_poi', ___to_post);
	}

//#endregion

//#region 																							U S E R S   S E T T I N G S

	async users_settings___language(___language: type___locale___language) : Promise<type___api___response>
	{
		const ___to_post: any = { 'language': ___language };
		return await this.return_server_response('users_settings', 'language', ___to_post);
	}

	async users_settings___update(___settings_collection: type___user_setting___property[]) : Promise<type___api___response>
	{
		const ___to_post: any = { 'settings_collection': ___settings_collection };
		return await this.return_server_response('users_settings', 'update', ___to_post);
	}

//#endregion

}