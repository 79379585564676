import React from 'react'

//	L O C A L I Z A T I O N

import { locale___ComEventTimeScheduleComponent } from './com-event-timeschedule.locale';

//	S T Y L E

import './com-event-timeschedule.scss'

//	S T A T E S   -   P R O P S

import { props___ComEventTimeScheduleComponent } from './com-event-timeschedule.state';

//	F U N C S

import { funcs_datetime } from '../../funcs/funcs.datetime';

//	S E R V I C E S 

import { service_LocalizationService } from '../../services/service-localization';

//	C L A S S

export default class ComEventTimeScheduleComponent extends React.Component<props___ComEventTimeScheduleComponent, {}>
{

//#region 																							C O N S T R U C T O R

	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

	private readonly funcs___datetime: funcs_datetime = new funcs_datetime();

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<section className="com-event-timeschedule---container">
	
				{(this.props.event_props['event_is_now'] === true) ? <>
					<div className="com-event-timeschedule---is-now"></div>
				</> : <>
					{(this.props.event_props['event_ended'] === true) ? <>
						<i className="far fa-calendar-times"></i>
					</> : <>
						<i className="far fa-calendar-alt"></i>
					</>}
				</>}
			
				<span></span>
			
				<section>
					{(this.props.event_props['event_is_now'] === true) ? <>
						<p style={{color:'#fd4755',fontSize:'12pt'}}>{ this._LOCALE.translate(locale___ComEventTimeScheduleComponent.event_now) }</p>
						<p>{ this._LOCALE.translate(locale___ComEventTimeScheduleComponent.event_now_begun, [this.funcs___datetime.datetime___get___time(this.props.event_props['event_datetime_begin'])]) }</p>
					</> : <>
						{(this.props.event_props['event_ended'] === true) ? <>
							<p>{ this._LOCALE.translate(locale___ComEventTimeScheduleComponent.event_ended) }</p>
							<p>{ this._LOCALE.translate(locale___ComEventTimeScheduleComponent.event_ended_datetime, [this.funcs___datetime.datetime___get___date(this.props.event_props['event_datetime_begin']), this.funcs___datetime.datetime___get___time(this.props.event_props['event_datetime_begin'])]) }</p>
						</> : <>
							<p>{ this._LOCALE.translate(locale___ComEventTimeScheduleComponent.event_begin_save_the_date) }</p>
							<p>{ this._LOCALE.translate(locale___ComEventTimeScheduleComponent.event_begin_datetime, [this.funcs___datetime.datetime___get___date(this.props.event_props['event_datetime_begin']), this.funcs___datetime.datetime___get___time(this.props.event_props['event_datetime_begin'])]) }</p>
						</>}
					</>}
				</section>

			</section>
		</>;
	}

//#endregion

}