import React from 'react';
import { IonButton, IonButtons, IonContent, IonHeader, IonModal, IonToolbar } from '@ionic/react';
import packageJson from '../../../package.json';

//	S T Y L E

import './md-entry-changelog.scss';

//	T Y P E S

import { type___modal___config } from '../../types/types.modals';

//	S T A T E   -   P R O P S

import { props___MdEntryChangeLogModal } from './md-entry-changelog.state';

//	S E R V I C E S

import { service_LocalizationService } from '../../services/service-localization';

//	C O M P O N E N T S

import ComMarkdownComponent from '../../components/com-markdown/com-markdown';
import { lib_names } from '../../libs/lib.names';

//	C L A S S

export default class MdEntryChangeLogModal extends React.Component<props___MdEntryChangeLogModal, {}>
{

//#region 																							C O N F I G

	private readonly MdEntryChangeLogModal___modal_config: type___modal___config = {
		breakpoints: [ 1, 0 ],
		backdropDismiss: true,
		handle: true,
		initialBreakpoint: 1,
		keyboardClose: true,
		showBackdrop: true,
		onDidDismiss: () => { this.handle___changeLog___viewed(); }
	};

//#endregion

//#region 																							D E C L A R A T I O N S
	
	private readonly _LOCALE: service_LocalizationService = new service_LocalizationService();

//#endregion

//#region 																							C O N S T R U C T O R

	constructor(
		public props: props___MdEntryChangeLogModal,
	) {
		super(props);
	}

//#endregion

//#region 																							D E C L A R A T I O N S

	private handle___changeLog___viewed = () => {
		const ___version___actual: string = packageJson['version'];
		localStorage.setItem(lib_names.localStorage.user___changelog_view, ___version___actual);
	}

//#endregion

//#region 																							L I F E C Y C L E

	private event___onDidDismiss = () => {
		this.handle___changeLog___viewed();
		this.props.event___onDidDismiss();
	}

//#endregion

//#region 																							R E N D E R

	render() : React.ReactNode
	{
		return <>
			<IonModal isOpen={ this.props.isOpen } { ...this.MdEntryChangeLogModal___modal_config }>

				<IonHeader>
					<IonToolbar>
						<IonButtons slot="end">
							<IonButton onClick={ this.event___onDidDismiss }>
								<b>chiudi</b>
							</IonButton>
						</IonButtons>
					</IonToolbar>
				</IonHeader>

				<IonContent forceOverscroll={ false }>
					<section className="md-entry-changelog---container">
						<img src="/assets/logo.horizontal.svg"/>
						<ComMarkdownComponent file_path={ '/assets/docs/changelog/changelog.' + 'it_it' + '.md' }/>
					</section>
				</IonContent>

			</IonModal>
		</>;
	}

//#endregion

}